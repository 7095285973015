import { graphql, PageProps } from "gatsby";
import React, { createContext, useEffect, useState } from "react";
import TermsNavigation from "../components/terms/TermsNavigation.component";
import { Layout } from "../components/layout/Layout.component";
import { ContentBlock } from "../shared/contentful/contentful.types";
import { PageContext, SEOContent } from "../shared/page.types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

type Props = {
  pageData: {
    title: string;
    seo: SEOContent;
    content: ContentBlock[];
  };
};

export const TermsContext = createContext<PageContext>({
  title: "",
  children: <></>,
});

const TermsAndConditions = ({
  data: { pageData },
  location: { href },
}: PageProps<Props>) => {
  const { title, content, seo } = pageData;

  const [contextValue, setContextValue] = useState<PageContext>({
    title: "",
    children: <></>,
  });

  useEffect(() => {
    setContextValue({
      title,
      children: <></>,
    });
  }, []);

  const seoProps = {
    ...seo,
    url: href,
  };

  return (
    <TermsContext.Provider value={contextValue}>
      <Layout pageNavigation={TermsNavigation} seo={seoProps}>
        <div className="boxed-container">
          {content.map((c) => renderRichText(c.content))}
        </div>
      </Layout>
    </TermsContext.Provider>
  );
};

export default TermsAndConditions;

export const query = graphql`
  query termsAndConditions($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulLayoutAllPages(
      identifier: { eq: "page_terms-conditions" }
      node_locale: { eq: $language }
    ) {
      title
      content {
        ...contentBlock
      }
      seo {
        ...seo
      }
    }
  }
`;
